<template>
  <a-upload
    name="file"
    :multiple="false"
    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=documentations-paragraphs&id=${documentationId}&sub-folder=`"
    :headers="{
      authorization: `Bearer ${this.$store.state.token}`,
    }"
    @change="uploadedParagraphFile"
    class="paragraph-file"
  >
    <a-icon
      v-if="[undefined, ''].includes(paragraphFile)"
      class="ico"
      type="file-zip"
    />
    <div v-if="paragraphFile">
      <a-icon class="file-ico" type="file-zip" />
      <div class="file-path">{{paragraphFile}}</div>
    </div>
  </a-upload>
</template>

<script>
export default {
  name: "DocumentationFileParagraph",
  props: {
    paragraph: Object,
    documentationId: String,
  },
  components: {},
  data() {
    return {
      paragraphFile: "",
    };
  },
  mounted() {
    this.paragraphFile = this.paragraph.content;
  },
  methods: {
    uploadedParagraphFile(response) {
      if (response.file.response.url != undefined) {
        this.paragraph.content = response.file.response.url;
        this.paragraphFile = response.file.response.url;

        this.$emit("startsLoading");

        this.$http.post("/documentation-paragraph/update", {
          id: this.paragraph.id,
          user_id: this.$store.state.userData.id,
          documentation_id: this.documentationId,
          type: this.paragraph.type,
          content: response.file.response.url,
          order: this.paragraph.order,
        }).then(() => {
          this.$emit("savedSuccessfully");
        });
      }
    },
  },
};
</script>

<style lang="sass">
.paragraph-file
  .ant-upload.ant-upload-select
    display: block
</style>

<style lang="sass" scoped>
.paragraph-file
  text-align: center
  padding: 20px 0
  img
    max-width: 100%
  .file-ico
    font-size: 54px
    color: #666
    padding: 20px 0 0
  .file-path
    font-size: 10px
    color: #bbb
    padding: 20px 0
  .ico
    font-size: 224px
    color: #eee
    cursor: pointer
</style>
